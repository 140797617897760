.map-section {
    width: 100%;
    margin-top: 120px;
    margin-bottom: 20px;
}

.map-section video {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .map-section {
        margin-top: 20px;
    }
}