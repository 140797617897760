.slider-container {
  margin-bottom: 50px;
}

.slick-arrow {
  filter: invert(1);
}

.slider-container .slick-slider img {
  width: 276px;
  height: 276px;
  object-fit: cover;
}

.swiper-content a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .slick-track {
  width: 100%!important;
} */

.slider-container .slick-slide {
  padding: 0 6px;
}

.slider-play-button {
  position: absolute;
  z-index: 10;
  width: 100px!important;
  height: 100px!important;
}

@media screen and (max-width: 768px) {
  .slider-container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .slick-prev , .slick-next{
    display: none !important;
  }

  


}

@media screen and (max-width: 480px) {
  .swiper-content {
    padding: 0;
  }
  


}

.st-video{
  font-size: 33px;
}