.info-left-div-2 {
    flex: 2;
    border-bottom: 1px solid #000;
    border-left: 1px solid #000;
    max-height: 400px;
  }

  .location-sec {
    padding-top: 50px!important;
  }

  .loc-h3{
    margin-bottom: 20px;
  }

  .loc-h3 h3{
    align-items: center;
    color: #22333b;
    display: flex;
    font-size: 34px;
    line-height: 35px;
    padding-left: 15px!important;
  }

  .loc-p{
    padding-right: 18px;
    margin-bottom: 50px;
  }

  .loc-p p{
    text-align: justify;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    padding: 0px 15px;
    overflow: hidden;
  }

  .explore-cont{
    display: flex;
    justify-content: center;
  }

  .explore-susha{
    display: flex;
    max-width: 250px;
    width: 100%;
    height: 50px;
    justify-content: center;
    border: none;
    align-items: center;
    border-radius: 16px;
    background: #DE2926;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    /* margin-bottom: 56px; */
  }

  @media screen and (max-width: 992px) {
    .info-left-div-2 {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .location-sec {
      margin-bottom: 0px!important;
    }

    .loc-p p {
      text-align: left;
    }
  }

  @media screen and (max-width: 500px) {
    .explore-susha {
      max-width: 200px;
      height: 40px;
      font-size: 16px;
    }

    .loc-h3 h3{
      font-size: 28px;
      line-height: 28px;
    }

    .loc-p p {
      font-size: 18px;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 375px) {
    
  .location-sec{
    padding-top: 50px !important;
  }

  .loc-p{
    padding: 0 15px;

  }

  .loc-p p{
    text-align: left;
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
    padding: 0;
    overflow: hidden;
  }

  .loc-h3{
    margin-bottom: 30px;
  }

  
}