.gallery-section {
    background: #22333B;
    width: 100%;
    height: 32rem;
    margin-bottom: 35px;
    margin-top: 35px;
}

/* .gallery-section .slick-slide:nth-child(odd) {
    max-width: 345px!important;
    height: 500px;
    object-fit: cover;  
} */

.gallery-section .slick-slide {
    max-width: 731px!important;
    /* height: 38vh; */
    object-fit: cover;  
}

.gallery-section .slick-slide div {
    width: 100%!important;
    height: 100%!important;
}

.gallery-slide-img {
    padding: 20px;
}

.gallery-slide-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .5s ease-in-out;
    filter: grayscale(100%);
}

.gallery-slide-img img:hover{
    filter: initial;
}

.gallery-section .slick-dots,
.gallery-section .slick-next,
.gallery-section .slick-prev {
    display: none!important;
}

.gallery-top {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 42px 0;
    /* padding-bottom: 0; */
}

.gallery-top h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.gallery-top a {
    padding: 8px 0;
    background-color: transparent;
    border: 1px solid #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    width: 123px;
    height: 39px;
    position: absolute;
    right: 150px;
    top: 20%;
    bottom: 50%;
    transform: translate(0, -35%);
}

@media screen and (min-width: 768px) {
    .gallery-speaker img{
        width: 100%;
        height: 260px;
        object-fit: cover;
    }
    .galery-con{
        margin-top: 40px;
    }
    .gallery-top a {
        top: -38%!important;
    }
}
@media screen and (max-width: 768px) {
    .gallery-speaker img{
        width: 100%;
        height: 111px;
        object-fit: cover;
    }
    .galery-con{
        margin-top: 25px;
    }
    .gallery-top {
        position: static;
        align-items: center;
        justify-content: end;
        gap: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .gallery-more {
        position: static!important;
        transform: translate(0,0)!important;
        margin-bottom: 0!important;
    }

    .gallery-section {
        height: auto;
        margin-bottom: 30px;
    }

    .gallery-top h3 {
        font-size: 40px;
        line-height: 40px;
    }

    .gallery-top a {
        padding: 11px 0;
        font-size: 16px;
        line-height: 18px;
        color: #FFFFFF;
        width: 130px;
        height: 40px;
    }

    .gallery-section .slick-slide {
        height: 280px!important;
    }
}

@media screen and (max-width: 480px) {
    .gallery-section .slick-slide {
        height: 350px!important;
    }
    .gallery-speaker img{
        width: 100%;
        height: 192px;
        object-fit: cover;
    }
    .galery-con{
        margin-top: 14px;
    }
}



/* @import "compass/css3"; */

html {
  background: #eff0ef;
}

h1 {
  font-size: 16px; 
  text-align: center;
  margin-bottom: 40px;
}

.testimonial-quote {
    font-size: 16px;
}

.salam {
    transition: transform 0.3s;
    transform: scale(1);
    overflow: visible;
    position: relative; /* Ensure positioning context */
}

.salam:hover {
    transform: scale(1.1);
    z-index: 10; /* Ensure it appears above other elements */
}

.galery-con {
    overflow: visible; /* Ensure no clipping */
    position: relative; /* Ensure proper stacking context */
    display: flex!important;
    justify-content: center;
}

.gallery-speaker {
    overflow: visible; /* Ensure no clipping */
    position: relative; /* Ensure proper stacking context */
    max-width: 350px;
}


.testimonial-quote blockquote {
    /* Negate theme styles */
    border: 0;
    margin: 0;
    padding: 0;

    background: none;
    color: white;
    /* font-family: Georgia, serif; */
    font-size: 16px;
    /* font-style: italic; */
    line-height: 1.4 !important;
    margin: 0;
    position: relative;
    /* text-shadow: 0 1px white; */
    z-index: 600;

    text-align: justify;
}

.testimonial-quote blockquote * {
    box-sizing: border-box; 
}

.testimonial-quote blockquote p {
    /* color: #ffffff;  */
    color: #75808a; 
    line-height: 1.4 !important;
}
.sitat-color{
    color: white!important;
    text-align: right;
    font-size: 14px!important;
    /* text-align: justify; */
}
/* .testimonial-quote blockquote p:first-child:before {
    content: '\201C';
    color: #81bedb;
    font-size: 7.5em;
    font-weight: 700;
    opacity: .3;
    position: absolute;
    top: -.4em;
    left: -.2em;    
    text-shadow: none;
    z-index: -300;
} */

.testimonial-quote img {
    border: 3px solid #9CC1D3;
    object-fit: cover;
    /* border-radius: 50%; */
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: -.2em; 
    left: 0;    
}

.testimonial-quote cite p{
    color: rgb(144, 142, 142);
    display: block;
    /* font-size: .8em;  */
}
  
.testimonial-quote cite span {
    color: white;
    font-size: 1em;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: 0 1px white; 
}

.testimonial-quote {
    position: relative; 
}

.testimonial-quote .quote-container {
    padding-left: 160px; 
    padding-right: 60px;
}
  
.testimonial-quote.right .quote-container {
    padding-left: 0;
    padding-right: 150px; 
}

.testimonial-quote.right img {
    left: auto;
    right: 0;
}

.testimonial-quote.right cite {
    text-align: justify; 
}