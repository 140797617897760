.background-container-forum1 {
    position: relative;
    width: 100%;
    /* height: 470px;  */
    overflow: hidden;
}

.background-image-forum1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* filter: brightness(0.5); */
}
.forum-about{
  text-align: justify;
}
.text-overlay-forum1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    text-align: center;
}

.other-blocks {
    padding: 20px;
    background-color: #f0f0f0;
    color: #333;
    margin-top: 20px;
}
.about-forum1{
    padding: 20px;
    /* background-color: #e0e0e0;  */
    color: #333;
    margin-top: 20px;
    border-radius: 8px; 
  }
  
  .about-forum1 h2 {
    margin-top: 0;
    text-align: center; 
    font-size: 34px;
}
.about-forum1 h3 {
  font-weight: 600;
}

.about-forum1 p {
    margin-bottom: 10px;
    font-size: 20px;
}
ul li{
  font-size: 20px;
}
/* .line{
  line-height: 1.2;
} */


.events-forum1 {
    padding: 20px;
    background-color: #ffffff;
    color: #333;
    margin-top: 20px;
    text-align: center;
}

.forum1-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.forum1-card {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.forum1-card-image {
    position: relative;
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.forum1-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.forum1-card-date {
    position: absolute;
    bottom: 0px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
}

.forum1-card-content {
    padding: 15px;
}

.forum1-card-content p {
    margin: 0 0 10px 0;
}

.forum1-card-content button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
}

.forum1-card-content button:hover {
    background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
    .forum1-cards-container {
        flex-direction: column;
        align-items: center;
    }

    .forum1-card {
        width: 100%;
    }
}
/* Forum1.css */
.background-container-forum1 {
    position: relative;
    text-align: center;
  }
  
  /* .background-image-forum1 {
    width: 100%;
     height: auto; 
  } */
  
  .text-overlay-forum1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
  }
  
  .about-forum1,
  .events-forum1,
  .speakers-forum1 {
    padding: 20px;
  }
  
  .forum1-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .forum1-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 10px;
    max-width: 300px;
    overflow: hidden;
  }
  
  .forum1-card-image {
    position: relative;
  }
  
  /* .forum1-card-image img {
    width: 100%;
    height: auto;
  } */
  
  .forum1-card-date {
    position: absolute;
    bottom: 0px;
    left: 77%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
  }
  
  .forum1-card-content {
    padding: 10px;
  }
  
  .forum1-card-content button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  .speaker-card:focus {
    outline: none;
  }
  .speakers-forum1 h2 {
    text-align: center;
  }
  
  .speaker-card {
    text-align: center;
    margin: 20px;
    transition: transform 0.3s;
    width: 220px!important;
  }
  .slick-prev, .slick-next {
    display: none !important;
  }
  .speaker-card img {
    max-width: 220px;
    width: 220px;
    /* min-width: 220px; */
    max-height: 220px;
    min-height: 220px;
    object-fit: cover;
    /* height: auto; */
    filter: grayscale(100%);
    transition: filter 0.3s;
  }
  
  .speaker-card:hover img {
    filter: grayscale(0%);
  }
  
  .speaker-card .name {
    font-size: 1.2em;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .speaker-card .position {
    font-size: 1em;
    color: gray;
  }
  
  /* .speakers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  } */
  
  /* @media (min-width: 600px) {
    .speakers-container {
      justify-content: space-around;
    }
  } */
  /* Forum1.css */

/* ... Digər stillər burada ... */

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    display: block;
    width: 40px; /* Ölçüyü 40px etdik */
    height: 40px; /* Ölçüyü 40px etdik */
    margin-top: -20px; /* Yüksəklikdə mərkəzləşdirmək üçün */
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: #333; /* Rəngi təyin etdik */
    font-size: 24px; /* Göstəricilərin ölçüsünü böyüdək */
    line-height: 1; /* Sətrlər arası boşluğu sıfır etdik */
}
.news_title h2{
  font-size: 17px;
}
.slick-prev {
    left: 10px; /* Sol-a getmək üçün aralığı təyin etdik */
}

.slick-next {
    right: 10px; /* Sağ-a getmək üçün aralığı təyin etdik */
}

.news-detail-wrapper h2{
  text-align: center;
}

.text-overlay-forum1 p{
  color: white;
}
.doc h2{
  /* border: 1px solid black; */
  display: block;
  padding: 13px;
  border-radius: 20px;
  background: #de2926;
  color: white;
  font-size: 20px;
  margin-top: 10px;
}
.topics h2{
  /* border: 1px solid black; */
  display: block;
  padding: 20px;
  border-radius: 20px;
  background: #de2926;
  color: white;
  font-size: 20px;
  margin-top: 10px;
}

.topic, .doc-up, .speaker-top, .news-top, .static-top{
  text-align:center;
  margin-top: 5%;
}