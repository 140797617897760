.image-gallery-image {
    width: 100%!important;
    height: 600px!important;
    object-fit: cover!important;
    max-height: none!important;
}

.gallery-inner-h3 {
    margin-left:20px;
    color: #22333B;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px; 
}

@media screen and (max-width: 992px) {
    .image-gallery-image {
        height: 500px!important;
    }
}

@media screen and (max-width: 768px) {
    .image-gallery-wrapper .image-gallery-image {
        height: 400px!important;
    }

    .gallery-inner-h3 {
        font-size: 28px;
        line-height: 28px;
    }
}

@media screen and (max-width: 500px) {
    .image-gallery-image {
        height: 300px!important;
    }
}