footer {
    background: #22333B;
    padding: 80px 0 20px 0;
}

.footer-logo-content p {
    font-family: 'CodecPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
    color: #fff;
    margin-top: 33px;
}

.footer-img {
    width: 173px;
    height: 71px;
}

.footer-img img {
    width: 100%;
    height: 100%;
}

.footer-links {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.footer-links span {
    font-family: 'CodecPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.231818px;
    color: #DE2926;
    margin-bottom: 10px;
}

.footer-links a {
    font-family: 'CodecPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
    color: #FFFFFF;
}

.footer-links a:hover {
    opacity: 0.8;
}

.footer-copyright {
    font-family: 'CodecPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.5;
    margin-top: 35px!important;
}

.footer-copyright span {
    color: #fff;
}

.footer-privacy {
    font-family: 'CodecPro-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
    color: #FFFFFF;
    display: flex;
    justify-content: flex-end;
    gap: 45px;
    margin-top: 35px!important;
}

.footer-privacy span {
    color: #fff;
}

#footer-logo{
    cursor: pointer;
}

.footer-sosial-icons {
    display: flex;
    align-items: center;
}

.footer-sosial-icons a svg path {
    color: #fff;
}

@media screen and (min-width: 992px){
    .footer-logo-content-col {
        flex: 0 0 auto;
        width: 50%!important;
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 23%!important;
    }

    .footer-links {
        padding: 0 40px;
    }
}

@media screen and (max-width: 991.5px) {
    .footer-links-col {
        margin-top: 20px!important;
    }

    .footer-privacy {
        justify-content: flex-start;
    }
}

@media screen and (max-width: 767.5px) {
    .footer-copyright {
        text-align: center;
    }

    .footer-links-col,
    .footer-links-col1 {
        margin-top: 20px!important;
    }

    .footer-privacy {
        justify-content: center;
    }
}

@media screen and (max-width: 576px) {
    .col-xs-6 {
        flex: 0 0 auto;
        width: 50%!important;
    }

    .footer-copyright,
    .footer-privacy,
    .footer-links a,
    .footer-links span,
    .footer-logo-content p {
        font-size: 13px;
    }
}