.faq-container {
  width: 100%;
  overflow: hidden;
  min-height: 800px;
  background: #22333b;
  box-shadow: 6px 7px 9px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-evenly;
  position: relative;
  margin-right: 0!important;
    margin-left: 0!important;
}

.accord div,
.photo {
  padding: 50px 100px 0 100px;
}

.accord img {
  max-width: 450px;
  /* width: 0%; */
  /* position: absolute; */
  /* left: 0;
  bottom: 0; */
}

.accord div h1 {
  color: #fff;
  font-size: 64px;
  font-family: "CodecPro-Regular";
  font-weight: 500;
  /* line-height: 25px; */
}

.accord div p {
  color: #fff;
  font-size: 22px;
  font-family: "CodecPro-Regular";
  font-weight: 300;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none !important;
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff !important;
  border: none !important;
  border-bottom: 1px solid #fff !important;
}

.nav-tabs {
  border: none !important;
  gap: 50px;
}

#myTab li button {
  color: rgba(255, 255, 255, 0.5);
  font-size: 40px;
  font-family: "CodecPro-Regular";
  font-weight: 600;
  text-align: left;
  line-height: 40px;
}

#myTab li button p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 24px;
  font-family: "CodecPro-Regular";
  font-weight: 300;
  text-align: left;
}

.tab-data1 div {
  margin: 10px 0;
  display: flex;
  gap: 30px;
  align-items: center;
}
.tab-data1 p {
  margin: 30px 0;
  color: #fff;
  font-size: 22px;
  font-family: "CodecPro-Regular";
  font-weight: bold;
  line-height: 25px;
  padding-bottom: 5px;
}

.tab-data1 span{
  font-weight:  normal !important;
  color: #fff;
}

.tab-data1 p:nth-child(1) {
  min-width: 200px;
}

.session-div{
  padding-top: 20px !important;
  padding-left: 0px !important;
  display: flex;
  align-items: center;
}

.session-div a{
  font-size: 20px;
  color: #fff;
  text-decoration: underline !important;
}

.anime-drop{
  color: #fff;
  font-size: 40px;
  margin-right: 10px;
  position: relative;
  right: 0;
  animation: drop 5s infinite;
}


@keyframes drop {
  0%{
    right: 0;
  } 

  25%{
    right: 20px;
  }

  100%{
    right: 0;
  }
}


@media screen and (max-width: 992px) {
  .accord img{
    display: none;
  }

  .accord div{
    padding-left: 12px !important;
  }
}




@media screen and (max-width: 768px) {
  .nav-tabs{
    justify-content: center;
  }

  .tab-data1 div{
    /* justify-content: center; */
    /* flex-wrap: wrap; */
    margin: 50px 0;
    /* border-bottom: 1px solid #fff; */
  }

  .tab-data1 p{
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }

  .accord img{
    display: none;
  }

  .photo{
    min-height: 600px;
  }
}

@media screen and (max-width: 600px) {
  .accord div{
    padding: 50px 15px 0 50px;
  }

  .accord div p {
    font-size: 17px;
    text-align: center;
  }

  .accord div{
    margin-top: 15px;
  }

  .faq-container {
    margin-left: 0 !important;
      
  }

  .accord div h1{
    font-size: 40px;
    text-align: center;
  }

  .tab-data1 div{
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    gap: 0;
    margin: 20px 0;
  }

  .session-div{
    justify-content: center;
  }

  #myTab li button p{
    font-size: 20px;
  }

  #myTab li button{
    font-size: 28px;
  }
}



@media screen and (max-width: 375px) {
  .accord div,
  .photo {
    padding: 10px 10px 0 10px;
    text-align: center;
  }

  .accord div p {
    font-size: 18px;
    margin-top: 15px;
  }

  .nav-tabs{
    gap: 30px;
  }

  .tab-data1 p:nth-child(1){
    min-width: initial;
  }
}
