.static-container {
  background: #f6f6f6;
  /* height: 420px; */
  margin-top: 5px;
  z-index: 5;
  position: relative;
  /* display: flex; */
  padding: 50px 0 150px 0;
}

.st-first {
  text-align: center;
  color: #22333B;
  font-size: 40px;
  font-family: "CodecPro-Regular";
  font-weight: 500;
  margin-bottom: 30px !important;
}

.bottom-img {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 35px;
}

.static1 {
  max-width: 310px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
  cursor: pointer;
  background: linear-gradient(180deg, #de2926 0%, #951f1d 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.static1 h1 div {
  color: #fff;
}

.static1:hover {
  background: var(--linear, linear-gradient(180deg, #ff0400 0%, #460100 100%));
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.static1 h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.static1 p {
  font-family: Arial, Helvetica, sans-serif !important ;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.static-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 80px;
  flex-wrap: wrap;
  align-items: stretch;
}

@media screen and (max-width: 768px) {
  .static1 h1 {
    font-size: 40px;
  }

  .static1 p {
    font-size: 16px;
  }

  .static1 {
    width: 230px;
    padding: 10px 0;
    gap: 0;
  }

  .st-first{
    font-size: 28px;
    line-height: 28px;
  }

  .static-container {
    padding: 60px 0 90px 0;
  }

  /* .photo img:nth-child(2){
    height: 500px;
  } */

  .static-inner {
    gap: 30px;
  }
}
