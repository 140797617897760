.preloader-section {
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.preloader-header {
    width: 100%;
    height: 95.39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.preloader-ul {
    display: flex;
    align-items: center;
    gap: 20px;
}

.pre-logo {
    width: 200px;
    height: 78px;
    background-color: #f0f0f0;
}

.pre-logo,
.preloader-li1,
.preloader-li2,
.preloader-li3, 
.preloader-li4, 
.preloader-li5 {
    background-color: #f0f0f0;
}

.preloader-li1,
.preloader-li2,
.preloader-li3, 
.preloader-li4, 
.preloader-li5 {
    height: 30px;
}

.preloader-li1 {
    width: 155px;
}
.preloader-li2 {
    width: 169px;
}
.preloader-li3 {
    width: 192px;
}
.preloader-li4 {
    width: 73px;
}
.preloader-li5 {
    width: 58px;
}

.preloader-middle {
    width: 100%;
    background-color: #f0f0f0;
    height: calc(100vh - 95.39px);
}


.logo-skeleton,
.li1-skeleton, 
.li2-skeleton, 
.li3-skeleton, 
.li4-skeleton, 
.li5-skeleton,
.middle-skeleton {
    animation: ske-loading 1s linear infinite alternate;
}

.preloader-menu-bar {
    display: none;
}

.menu-bar-skeleton {
    animation: menu-loading 1s linear infinite alternate;
}

@keyframes ske-loading {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes menu-loading {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes li1-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes li2-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes li3-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes li4-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes li5-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}
@keyframes middle-skeleton {
    0% {
        background-color: rgb(240,240,240);
    }

    100% {
        background-color: rgb(250,250,250);
    }
}

@media screen and (max-width: 674.5px) {
    .preloader-menu-bar {
        display: block;
        width: 35px;
        height: 35px;
        background-color: #f0f0f0;
        position: absolute;
        right: 2.5%;
    }

    .preloader-li1, 
    .preloader-li2, 
    .preloader-li3, 
    .preloader-li4 {
        display: none;
    }

    .preloader-li5 {
        height: 23px;
        width: 45px;
        display: block;
        position: absolute;
        right: 20%;
    }

    .pre-logo {
        width: 160.8px;
        height: 70.43px;
    }
}